<template>
  <div>
    <Heard />
    <div class="banner"></div>
    <div class="big-box container-fuild">
      <div class="container">
        <p class="nav"></p>
        <!-- <Newlist /> -->
        <div class="news-box">
          <div class="box-left">
            <div class="left-nav">
              <p
                :class="{ span: true, curcolor: item.id == catId }"
                v-for="item in typelist"
                :key="item.id"
                @click="clickitem(item)"
              >
                <span>{{ item.name }}</span>
              </p>
              <!-- <p class="span"><span>面试辅导</span></p>
              <p class="span"><span>简历优化</span></p>
              <p class="span curcolor"><span>工作推荐</span></p> -->
            </div>

            <div class="left-content">
              <!-- :href="`/news/id=${item.id}&typeid=${item.catId}`" -->

              <div class="left-item" v-for="item in list" :key="item.id">
                <a :href="`/news/${item.id}.html`" :title="item.title"
                  ><img :src="item.image" :alt="item.title"
                /></a>
                <div class="left-smallbox">
                  <a :href="`/news/${item.id}.html`" :title="item.title"
                    ><p class="one">{{ item.title }}</p></a
                  >
                  <a :href="`/news/${item.id}.html`" :title="item.title"
                    ><p class="two">
                      {{ item.description }}
                    </p></a
                  >
                  <p class="thir">
                    <i class="el-icon-time"></i> 发布时间 : {{ item.inputTime }}

                    <span>
                      <i class="el-icon-user"></i> 作者 :
                      {{ item.keywords }}</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <!-- <Pagination
              :total-count="200"
              :limit="25"
              :get-by-page="getByPage"
            /> -->
            <!-- <div class="pages">
              <p class="all">一共{{ totalCount }}页</p>
              <nuxt-link
                v-if="curpage != 1"
                :to="{ path: `/list-2-${curpage - 1}` }"
                >上一页</nuxt-link
              >
              <span>{{ curpage }}</span>
           
              <nuxt-link :to="{ path: `/list-2-${curpage + 1}` }"
                >下一页</nuxt-link
              >
            </div> -->
            <el-pagination
              background
              :current-page="currPage"
              @current-change="pagechange"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
          <div class="box-right">
            <div class="hot" v-if="rightlist">
              <div class="right-nav">
                <div class="line"></div>
                <span>热门推荐</span>
              </div>
              <a
                :href="`/news/${item.id}.html`"
                v-for="item in rightlist.slice(0, 10)"
                :title="item.title"
                :key="item.id"
              >
                <div class="right-content">
                  <p class="tit">{{ item.title }}</p>
                  <p class="time">
                    <i class="el-icon-time"></i> {{ item.inputTime }}
                  </p>
                </div></a
              >
            </div>

            <div class="right-bot" v-if="rightlist">
              <div class="right-nav">
                <div class="line"></div>
                <span>相关推荐</span>
              </div>
              <div class="right-content imgitem">
                <div
                  class="right_item"
                  v-for="item in rightlist.slice(11, 17)"
                  :key="item.id"
                >
                  <a :href="`/news/${item.id}.html`" :title="item.title">
                    <img :src="item.image" :alt="item.title"
                  /></a>
                  <a :href="`/news/${item.id}.html`" :title="item.title">
                    <p class="imgtit">{{ item.title }}</p></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script >
import Heard from "@/components/Heard.vue";
// import Newlist from "@/components/Newlist.vue";
import Footer from "@/components/Footer.vue";
import { api_queryNewsTitleList } from "@/apis/apis.js";
export default {
  components: {
    Heard,
    // Newlist,
    Footer,
  },

  data() {
    return {
      totalCount: 1,
      currPage: 1,
      list: [],
      rightlist: [],
      catId: "",
      typelist: [
        { name: "全部文章", id: "" },
        { name: "面试辅导", id: 2 },
        { name: "简历优化", id: 3 },
        { name: "工作推荐", id: 4 },
      ],
    };
  },

  async created() {
    await this.getlist();
    await this.getrightlist();
  },
  methods: {
    clickitem(item) {
      this.catId = item.id;
      this.getlist();
      this.getrightlist();
    },
    getlist() {
      api_queryNewsTitleList({
        catId: this.catId,
        currPage: this.currPage,
      }).then((res) => {
        this.list = res.data.data.dataList;
        this.totalCount = Math.floor(res.data.data.totalCount);
      });
    },
    getrightlist() {
      api_queryNewsTitleList({
        catId: this.catId,
        pageSize: 20,
        currPage: 3,
      }).then((res) => {
        this.rightlist = res.data.data.dataList;
      });
    },
    pagechange(page) {
      this.currPage = page;
      this.getlist();
    },
  },
};
</script>

<style lang="less" scoped>
.container-fluid {
  min-width: 1200px;
}
/* 版心 */
.container {
  width: 1200px;
  margin: 0 auto;
}
.banner {
  height: 302px;
  width: 100%;
  background: url(~@/assets/image/f3.png) no-repeat center;
}
.big-box {
  background-color: #f5f5f5;
  .nav {
    padding: 15px 0;
    line-height: 50px;
  }
}
.news-box {
  display: flex;
  .box-left {
    background-color: #fff;
    width: 790px;
    border-radius: 8px;
    padding-bottom: 40px;
    margin-right: 20px;
    .left-nav {
      display: flex;
      justify-content: space-around;
      border-bottom: 1px solid #ccc;
      p {
        color: #999;
        cursor: pointer;
      }
      span {
        height: 60px;
        line-height: 60px;
        width: 150px;
      }
      .curcolor {
        color: #5751fb !important;
      }
    }
    .left-content {
      padding: 20px;
      .left-item {
        margin-bottom: 18px;
        display: flex;
        img {
          width: 260px;
          height: 178px;
          margin-right: 18px;
        }
        .left-smallbox {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .one {
            color: #53526e;
            font: 16px/16px "微软雅黑";
            max-height: 50px;
            overflow: hidden;
            word-break: break-all;
          }
          .two {
            font-size: 12px;
            color: #999;
            line-height: 25px;
            overflow: hidden;
            margin-bottom: 26px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            display: -webkit-box;
          }
          .thir {
            color: #53526e;
            font-size: 14px;
            span {
              margin-left: 16px;
            }
          }
        }
      }
    }
    .el-pagination {
      margin-top: 40px;
      margin-left: 40px;
    }
  }
  .box-right {
    width: 390px;

    .hot {
      background-color: #fff;
      padding-bottom: 20px;
      border-radius: 8px;
      a {
        color: #53526e;
      }
    }
    .right-nav {
      display: flex;
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px;
      .line {
        width: 2px;
        height: 36px;
        margin-top: 12px;
        background-color: #5e58fb;
        margin: 12px 10px 0;
      }
      span {
        color: #999;
        height: 60px;
        line-height: 60px;
        width: 150px;
      }
    }
    .imgitem {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      a {
        color: #53526e;
        width: 50%;
      }
    }
    .right-content {
      padding: 10px;
      .tit {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .time {
        font-size: 12px;
        margin-left: 10px;
        margin-top: 6px;
      }
      .right_item {
        width: 48%;
        margin-bottom: 16px;
        text-align: center;
        img {
          width: 150px;
          height: 103px;
          margin-bottom: 8px;
        }
        .imgtit {
          font-size: 14px;
        }
      }
    }
    .right-bot {
      margin-top: 20px;
      background-color: #fff;
      border-radius: 8px;
      position: sticky;
      top: 20px;
    }
  }
}
.pages {
  display: flex;
  justify-content: center;
  span {
    margin: 0 16px;
  }
  .all {
    margin-right: 12px;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
</style>